import { graphql, Link } from 'gatsby';
import React from 'react';
import { Column, Container, Row } from '../components/Grid';
import Layout from '../components/Layout';
import Quote from '../components/Quote';
import Section from '../components/Section';
import '../styles/templates/_mentor.scss';
import { renderResources } from '../utils/resource-helper';

const baseUrl = '/annual-challenge/finalists-mentors-judges';
const Mentor = ({ data }) => {
  const mentor = data.mentor;
  const mentees = data.mentees.nodes;

  return (
    <Layout
      title={`Mentor: ${mentor.name}`}
      className="annual-challenge finalists-mentors-judges mentor"
    >
      <Section>
        <Container>
          {/* Breadcrumbs */}
          <ul className="breadcrumbs no-hero">
            <li className="breadcrumb">
              <Link to="/annual-challenge/about-the-challenge/">
                Annual Challenge
              </Link>
            </li>
            <li className="breadcrumb">
              <Link to={baseUrl}>Finalists, Mentors and Judges</Link>
            </li>
            <li className="breadcrumb">
              <Link to={`${baseUrl}/mentors#innovators-anchor`}>Mentors</Link>
            </li>
            <li className="breadcrumb active">
              {mentor.prefix} {mentor.name}
            </li>
          </ul>
          {/* Mentor Prfile */}
          <Row>
            <Column size={7} offset={1}>
              <div className="profile-intro">
                <h3 className="meet-profile">Meet the Mentor:</h3>
                <h1>
                  {mentor.prefix} {mentor.name}
                </h1>
                <h2 className="subtitle">{mentor.jobTitle}</h2>
                <h3 className="subtitle">{mentor.jobDivision}</h3>
              </div>
              <div className="profile-description">
                {mentor.longDescription.map((paragraph, i) => (
                  <p key={i}>{paragraph}</p>
                ))}
              </div>
            </Column>
            <Column size={4}>
              <Quote image={mentor.quoteImage} text={mentor.shortDescription} />
            </Column>
          </Row>
        </Container>
      </Section>
      {/* Mentees */}
      <Section>
        <Container>
          <Row>
            <Column size={12} className="mentors-mentees mt-4">
              {mentees && <h2>Mentees</h2>}
              {mentees && renderResources('students', mentees)}
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    mentor: mentorsYaml(slug: { eq: $slug }) {
      prefix
      name
      jobTitle
      jobDivision
      longDescription
      shortDescription
      quoteImage
    }
    mentees: allStudentsYaml(
      sort: {
        order: [DESC, ASC, ASC]
        fields: [participations___year, firstName, lastName]
      }
      filter: {
        mentor: { slug: { eq: $slug } }
        participations: {
          elemMatch: { award: { regex: "/^Finalist$|^Winner$/i" } }
        }
      }
    ) {
      nodes {
        firstName
        lastName
        middleName
        tag
        id
        cardImage
        ideaName
        aboutIdea
        participations {
          award
          year
        }
        slug
      }
    }
  }
`;

export default Mentor;
