import React from 'react';
import { JudgeCard, MentorCard, StudentCard } from '../components/Card';
import { Column, Row } from '../components/Grid';

const deslugify = (slug) => {
  let splitSlug = slug.split('-');
  let camelCased = '';

  for (let i = 1; i < splitSlug.length; i++) {
    splitSlug[i] = splitSlug[i].charAt(0).toUpperCase() + splitSlug[i].slice(1);
  }

  camelCased = splitSlug.join('');
  return camelCased;
};

/**
 * Extracts the resources from the data object and flattens it to an
 * array of resources (objects).
 *
 * @param {Object} data GraphQL query response
 *
 * @returns {Array} An array of resources (objects)
 */
export const getRawResources = (data) =>
  data.allResourcesJson.edges.reduce((acc, curr) => {
    acc.push(curr.node);
    return acc;
  }, []);

/**
 * Extracts the resources from the data object and modifies it into
 * an object with keys generated from the resource slug value. So a
 * resource with a slug value of "slug-name" will have a key of
 * "slugName". This is best used to destructure the resources you
 * want from the query.
 *
 * @param {Object} data GraphQL query response
 *
 * @returns {Object} An object with camel case keys generated from
 * the slug property of a resource.
 */
export const getKeyedResources = (data) =>
  getRawResources(data).reduce((acc, curr) => {
    acc[deslugify(curr.slug)] = curr;
    return acc;
  }, {});

/**
 * Renders all the cards with the correct number of rows
 *
 * @param {String} type Can be 'mentors', 'students', or 'judges'
 * @param {Object} resources The Graphl query response containing
 * the mentors, students, judges
 *
 * @returns {Object} The HTML to render the data
 */
export const renderResources = (type, resources) =>
  resources && resources.length > 0 ? (
    <>
      {resources
        .reduce((acc, curr, index) => {
          const accIndex = Math.floor(index / 3);
          if (acc[accIndex] === undefined) {
            acc[accIndex] = [curr];
          } else {
            acc[accIndex].push(curr);
          }
          return acc;
        }, [])
        .map((resRow) => renderRowOfResources(type, resRow))}
    </>
  ) : (
    <div className="d-flex">
      <h4 className="m-auto">
        Currently, there are no results for your selection. Use the year and
        category filters above to search for another selection.
      </h4>
    </div>
  );

const renderRowOfResources = (type, resources) => {
  return (
    <Row key={resources[0].firstName || resources[0].name + resources[0].slug}>
      {resources.map((res, index) => (
        <Column size={4} key={res.firstName || res.name + index}>
          {type === 'mentors' && <MentorCard mentor={res} />}
          {type === 'students' && <StudentCard student={res} />}
          {type === 'judges' && <JudgeCard judge={res} />}
        </Column>
      ))}
    </Row>
  );
};
