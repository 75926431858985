import { Link } from 'gatsby';
import React from 'react';
import Image from '../Image';
import './styles.scss';

const GetFullNameWithPrefix = (prefix, name) => {
  if (prefix) {
    return `${prefix} ${name}`;
  } else {
    return name;
  }
};

const MentorCard = ({ mentor }) => {
  return (
    <div className="profile-card box-shadow hover imagezoom">
      <div className="profile-card__image">
        <Image filename={mentor.cardImage} />
      </div>
      <div className="profile-card__content">
        <h3>{GetFullNameWithPrefix(mentor.prefix, mentor.name)}</h3>
        <p className="text-bold">{mentor.jobTitle}</p>
        <p>{mentor.shortDescription}</p>
        <Link
          to={`/annual-challenge/finalists-mentors-judges/mentors/${mentor.slug}`}
          className="profile-card__cta"
        >
          Learn More &gt;
        </Link>
      </div>
    </div>
  );
};

export default MentorCard;
