import PropTypes from 'prop-types';
import React from 'react';
import Image from '../Image';
import './styles.scss';

const Quote = ({ image, text }) => {
  return (
    <div className="quote">
      <Image filename={image} className="quote__image" />
      <div className="quote__white-bg" />
      <div className="quote__text-div">
        <p className="quote__text-div__text">{text}</p>
      </div>
      <div className="quote__gradient" />
    </div>
  );
};

Quote.propTypes = {
  image: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Quote;
