import { Link } from 'gatsby';
import React from 'react';
import Image from '../Image';
import './styles.scss';

const GetFullNameWithPrefix = (prefix, name) => {
  if (prefix) {
    return `${prefix} ${name}`;
  } else {
    return name;
  }
};

const JudgeCard = ({ judge }) => {
  return (
    <div className="profile-card box-shadow hover imagezoom">
      <div className="profile-card__image">
        <Image filename={judge.cardImage} />
      </div>
      <div className="profile-card__content">
        <h3>{GetFullNameWithPrefix(judge.prefix, judge.name)}</h3>
        <p className="text-bold">{judge.jobTitle}</p>
        <p>{judge.shortDescription}</p>
        <Link
          to={`/annual-challenge/finalists-mentors-judges/judges/${judge.slug}`}
          className="profile-card__cta"
        >
          Learn More &gt;
        </Link>
      </div>
    </div>
  );
};

export default JudgeCard;
