/* eslint-disable */
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import React from 'react';
import { urlJoin } from '../../utils/formatters';
import Image from '../Image';
import './styles.scss';

const GetFullName = (firstName, middleName, lastName) => {
  return middleName
    ? `${firstName} ${middleName} ${lastName}`
    : `${firstName} ${lastName}`;
};

const SlugifyAward = (award) => {
  return `${award.toLowerCase().replaceAll(' ', '-')}s`;
};

const GetUrl = (student) => {
  const pathname = useLocation().pathname;
  const baseUrl = '/annual-challenge/finalists-mentors-judges';
  const winnersRegex = new RegExp(
    `^\/?${baseUrl}\/finalists|winners|state-merit-winners|honorable-mentions|improving-lives\/?$`
  );
  if (winnersRegex.test(pathname)) {
    return urlJoin(pathname, student.slug);
  }
  try {
    const studentAward = student.participations[0].award.toLowerCase();
    return urlJoin(baseUrl, SlugifyAward(studentAward), student.slug);
  } catch {
    return urlJoin(baseUrl, 'improving-lives', student.slug);
  }
};

const StudentCard = ({ student }) => {
  return (
    <div className={`${student.tag} profile-card box-shadow hover imagezoom`}>
      <div className="profile-card__image">
        {student.cardImage?.[0] ? (
          <Image filename={student.cardImage} />
        ) : (
          <Image filename="Placeholder-Card.jpg" />
        )}

        {/** <div className="profile-card__flag">{student.flag}</div> **/}
      </div>
      <div className="profile-card__content">
        <div className="tag">{student.tag}</div>
        <h3>
          {GetFullName(student.firstName, student.middleName, student.lastName)}
        </h3>
        <h4 className="idea-name">{student.ideaName}</h4>
        <p className="idea-content">{student.aboutIdea}</p>
        {student.aboutIdea?.[0] &&
          student.challengeMotivation?.[0] &&
          student.favoriteInvention?.[0] &&
          student.dreamOcupation?.[0] && (
            <Link to={GetUrl(student)} className="profile-card__cta">
              Learn More &gt;
            </Link>
          )}
      </div>
    </div>
  );
};

export default StudentCard;
